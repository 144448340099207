body {
    background-color: #191B1D;
}

.row-container {
    position: relative;
    max-width: 1700px;
    // max-width: 1295px;
    width: 90%;
    margin: 0 auto;
    z-index: 5;

    * {
        ::selection {
            user-select: none;
        }
    }
}

.row-grid {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 1700px;
    width: 100%;
    height: 100%;
    z-index: 4;

    @include flex(space-evenly);

    @include tablet {
        justify-content: space-around;
    }

    .item {
        position: relative;
        min-width: 1px;
        border-left: 1px solid rgba($color: #fff, $alpha: 0.095);
        transition-timing-function: ease-out;
        
    }
}