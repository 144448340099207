@keyframes progressAnimation {
    0% {
      background-position-x: 0px;
    }
    
    100% {
      background-position-x: 10000px;
    }
}

@keyframes imageScaleAnimation {
    0% {
       transform: scale(1);
    }

    50% {
       transform: scale(1.05);
    }

    100% {
       transform: scale(1);
    }
}

.calculator-section {
    position: relative;
    padding-top: 40px;

    .heading {
        @include flex(space-between);

        h2 {
            display: inline-block;
            max-width: 940px;
            @include fontsize(45px,32px,26px);
            font-weight: 500;
            color: #fff;
            text-transform: uppercase;
            // transition: all .35s ease-in-out;

            @include desktop {
                @media (max-width: 1315px) {
                    max-width: 700px;
                    font-size: 35px !important;
                }

                @media (max-width: 1080px) {
                    max-width: 600px;
                    font-size: 30px !important;
                }
            }

            @include tablet {
                margin-bottom: 0;
            }
        }

        .bonus-info {
            display: inline-block;
            max-width: 243px;
            margin-top: 15px;

            @include tablet {
                margin-bottom: 40px;
            }

            h3 {
                @include fontsize(20px,19px,18px);
                font-weight: 500;
                color: #fff;
                text-transform: uppercase;
                max-width: 200px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                padding-left: 20px;
                margin-top: 22px;

                li {
                    position: relative;
                    font-family: "Roboto", sans-serif;
                    font-size: 14px;
                    color: rgba($color: #fff, $alpha: .72);
                    margin-bottom: 15px;

                    &::before {
                        content: "";
                        position: absolute;

                        width: 12px;
                        height: 9px;

                        background-image: url('http://localhost:3000/images/list-check.png');
                        background-size: contain;

                        left: -20px;
                        top: 3px;
                    }
                }
            }
        }
    }

    .disklamer-items {
        @include flex(space-between);
        margin-top: 6px;
        max-width: 920px;

        .item {
            @include flex(flex-start);
            align-items: center;

            @include tablet {
                margin-bottom: 20px;
                width: 100%;
            }
        

            .number {
                @include flex(center);
                align-items: center;

                background-color: #D18945;

                width: 40px;
                height: 40px;

                color: #fff;
                font-family: "Roboto", sans-serif;
                @include fontsize(20px,19px,18px);
                font-weight: 500;

                border-radius: 100px;
            }
            .text-wrap {
                padding-left: 20px;
                @include tablet {
                    padding-left: 1.5%;
                    width: calc(100% - 55px);
                }

                @include phone {
                    padding-left: 3%;
                    width: calc(93% - 49px);
                }

                p {
                    font-family: "Roboto", sans-serif !important;
                    font-size: 16px;
                    color: rgba($color: #fff, $alpha: .6);
                    margin: 0;

                    strong {
                        font-family: "Roboto", sans-serif !important;
                        display: block;
                        font-weight: 500;
                        color: #fff;
                    }
                }
            }
        }
    }

    .calc-wrap {
        @include flex(space-between);
        margin-top: 65px;

        .left-col {
            width: calc(100% - 450px);

            @include desktop {
                @media (max-width: 1180px) {
                    width: calc(100% - 380px);
                }
            }

            @include tablet {
                width: 100%;
            }
        }

        .right-col {
            width: 405px;

            @include desktop {
                @media (max-width: 1180px) {
                    width: 340px;
                }
            }

            @include tablet {
                max-width: 450px;
                margin: 0 auto;
                margin-top: 60px;
            }

            @include phone {
                @media (max-width: 600px) {
                    width: 100%;
                    max-width: 100%;
                    max-height: 500px;
                }
            }


            .kirill {
                position: relative;
                border-radius: 30px;
                height: 100%;
                overflow: hidden;
    
                &::after {
                    position: absolute;
                    content: "";
    
                    width: 100%;
                    height: 100%;
    
                    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6601234243697479) 88%);
                
                    bottom: 0;
                    left: 0;
    
                    z-index: 5;
                }
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                    animation: imageScaleAnimation 10s infinite ease-in-out;
                }
    
                .text-wrap {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    padding: 40px 35px;
                    z-index: 6;

                    @include phone {
                        @media (max-width: 600px) {
                            padding: 20px 15px;
                        }
                    }
    
                    h3 {
                        position: relative;
                        max-width: 275px;
                        font-family: "Roboto", sans-serif;
                        @include fontsize(18px,17px,16px);
                        font-weight: 500;
                        color: #fff;
                        text-transform: uppercase;
                        margin-bottom: 20px;
    
    
                        &::before {
                            content: "";
                            position: absolute;
    
                            width: 17px;
                            height: 17px;
    
                            left: 0;
                            top: -34px;
    
                            background-image: url('http://localhost:3000/images/quote-icon.png');
                            background-size: contain;
                        }
                    }
    
                    p {
                      max-width: 220px;
                      font-family: "Roboto", sans-serif;
                      font-weight: 400;
                      font-size: 14px;
                      color: #fff; 
                      margin: 0;
                    }
                }
            }
        }

        .progress-bar {
            width: 100%;
            height: 28px;
            background-color: #262A2D;
            border-radius: 100px;
            padding: 6px;

            @include tablet {
                width: auto;
            }
            

            .progress {
                height: 100%;
                background-color: #EB994C;
                background-image: url('http://localhost:3000/images/progress-texture.jpg');
                background-repeat: repeat-x;
                animation: progressAnimation 400s infinite linear;
                background-size: 700px;
                border-radius: 100px;

                transition: all .35s ease-out;
            }

            .progress-title {
                display: block;
                padding-top: 15px;
                width: 100%;
                text-align: center;
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                color: #fff;
            }
        }

        .calc-steps-wrapper {
            h3 {
                @include fontsize(28px,24px,22px);
                color: #fff;
                margin-top: 44px;
            }

            ul {
                @include flex(space-between);
                padding: 0;
                list-style: none;

                li {
                    width: 31%;
                    margin-bottom: 5px;
                    cursor: pointer;

                    @media (max-width: 700px) {
                        width: 47%;
                    }

                    img {
                        width: 100%;
                        border-radius: 20px;
                        transition: all .15s ease-in-out;
                        border: 1px solid #191b1d;
                        opacity: .7;

                        @include phone {
                            border-radius: 15px;
                        }
                    }

                    h4 {
                        font-family: "Roboto", sans-serif;
                        font-size: 14px;
                        color: #D9E2EB;
                        text-align: center;
                        margin-top: 5px;
                    }

                    &:hover {
                        img {
                            transform: scale(1.02);
                        }
                    }

                    &.active {
                        h4 {
                          color: #D18945;
                        }

                        img {
                            border-color: #D18945;
                            opacity: 1;
                        }
                    }
                }
            }

            .inputs {
                @include flex(space-between);

                &:not(:first-child) {
                    margin-top: 30px;
                    margin-bottom: 20px;
                }

                label {
                    @include flex(flex-start);
                    color: #fff;
                    width: 48%;


                    @include phone {
                        width: 100%;
                        margin-bottom: 15px;

                    
                    }

                    input {
                        display: block;
                        background-color: #262a2d;
                        color: #fff;
                        margin-top: 8px;

                        height: 40px;
                        width: 100%;

                        border-radius: 10px;
                        box-shadow: none;
                        border: none;
                        padding: 5px;
                        padding-left: 20px;

                        font-size: 16px;

                        outline-color: #EB994C;
                        user-select: all !important;
                        
                        
                        
                    }
                }

                &.sizes {
                        
                    @include phone {
                        label {
                            width: 48%;
                        }
                    }
                    
                }
            }

            .results {
                margin-bottom: 20px;
                .item {
                    @include flex(space-between);
                    align-items: center;
                    border-bottom: 1px solid #303030;
                    background: #191b1d;

                    h4 {
                        display: inline-block;
                        font-size: 18px;
                        color: #fff
                    }

                    img {
                        display: none;
                        max-width: 100px;

                    }

                    h5 {
                        display: inline-block;
                        font-size: 18px;
                        color: #fff
                    }

                    &.price {
                        strong {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .calc-footer {
            @include flex(space-between);
            align-items: center;
         

            .text-wrap {
                p {
                    font-size: 16px;
                    font-family: "Roboto", sans-serif;
                    color: rgba($color: #fff, $alpha: .6);

                    strong {
                        display: block;
                        color: #fff;
                        font-family: "Roboto", sans-serif;

                    }
                }
            }

            .buttons-wrap {



                @include desktop {
                    @media (max-width: 1080px) {
                        margin-top: 15px;
                        width: 100%;
                        @include flex(space-between);
                    }
                }

                @include phone {
                    @media (max-width: 625px) {
                        margin-top: 15px;
                        width: 100%;
                        @include flex(space-between);
                    }
                }

                button {
                    background-color: #D18945;
                    color: #fff;
                    @include fontsize(16px,15px,14px);
                    font-weight: 500;
                    box-shadow: none;
                    border-radius: 100px;
                    text-transform: uppercase;
                    padding: 13px 30px;
                    border: none;
                    transition: all .15s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: #fff;
                        color: #000;
                    }


                    &.back {
                        color: #fff !important;
                        background: transparent !important;

                        &:hover {
                            opacity: .6;
                        }
                    }

                }
            }
        }

       
    }

}