@keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

.hero-section {
    position: relative;
    width: 100%;

    height: 100vh;
    max-height: 1080px;
    overflow: hidden;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;

        animation: scaleAnimation 30s infinite;

        .gradient {
            position: absolute;
            width: 100%;
            height: 100%;

            top: 0;
            left: 0;

            &.fill {
                background: linear-gradient(96deg, rgba(0, 0, 0, 0.7) 0%, rgba(102, 102, 102, 0) 56%);
            }

            &.top {
                height: 160px;
                background: linear-gradient(1deg, rgba(0, 0, 0, 0) 20%, rgb(0, 0, 0) 100%);
            }

            &.right {
                height: 100%;
                width: 32%;
                left: unset;
                right: 0;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 130%);

                @include tablet {
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 110%);
                }

                @include phone {
                    width: 50%;
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top right;
            // animation: scaleAnimation 30s infinite;
        }
    }

    .row-grid {
        .dot {
            position: absolute;

            @include tablet {
                display: none;
            }

            &::before {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                background-color: #fff;

                left: -4.8px;


                @include desktop {
                    @media (max-width: 1400px) {
                        width: 7px;
                        height: 7px;
                        left: -3.8px;
                    }
                }
            }

            p {
                color: #fff;
                padding-left: 17px;
                width: 135px;
                margin-top: -6px;
                font-size: 20px;

                @media (max-width: 1400px) {
                    font-size: 10px;
                    max-width: 120px;
                }
            }

            &:nth-child(1) {
                top: 30%;
            }

            &:nth-child(2) {
                top: 200%;
                opacity: 1;
                transition: all 0.7s ease-out

                
            }
        }
    }

    .row-container {
        @include flex(space-between);
        height: calc(100% - 140px);
        position: relative;
        z-index: 5;
        padding-top: 140px;

        @include tablet {
            padding-top: 100px;
        }

        @include phone {
            padding-top: 150px;
        }

        .left-col {

            width: calc(100% - 220px);
            @include flex(space-between);
            align-content: center;
            margin-top: -175px;

            @media (max-width: 600px) {
                width: 100%;
                text-align: center;
            }


            h1 {
                max-width: 570px;
                @include fontsize(70px,55px,38px);
                font-weight: 500;
                color: #fff;
                text-transform: uppercase;
                margin-bottom: 0;

                @include desktop {
                    @media (max-width: 1400px) {
                        max-width: 380px;
                        font-size: clamp(40px, 3.5vw, 50px) !important;
                    }
                }

                @media (max-width: 600px) {

                }
            }

            p {
                display: none;

                @media (max-width: 600px) {
                    display: block;
                    color: #fff;
                    max-width: 340px;
                    margin: 0 auto;
                    margin-top: 20px;
                }
            }

            .buttons {
                @include flex(flex-start);
                width: 100%;
                margin-top: 70px;

                @include tablet {
                    flex-direction: column;
                }



                @media (max-width: 600px) {
                    justify-content: center;
                    flex-direction: row;
                }
                
                a.cta {

                    @include flex(center);
                    display: inline-flex;
                    align-items: center;
                    text-align: center;

                    width: fit-content;
                    min-width: 275px;
                    // height: 45px;
                    font-family: "Roboto", sans-serif;
                    @include fontsize(18px,18px,16px);
                    letter-spacing: 0.4px;
                    font-weight: 500;
                    color: #000;
                    text-decoration: none;
                    text-transform: uppercase;

                    background-color: #fff;
                    padding: 15px 5px;

                    border-radius: 100px;
                    border: 1px solid transparent;
                    
                    &:not(.no-transition) {
                        transition: all .2s ease-in-out;
                    }

                    @include desktop {
                        @media (max-width: 1400px) {
                           height: auto;
                           font-size: 14px !important;
                           padding: 10px 5px;
                           min-width: 220px;
                        }
                    }

                    @include tablet {
                        padding: 20px 5px;
                    }

                    &:hover {
                        background-color: #D18945;
                        color: #fff !important;
                    }

                    &.whatsapp {
                        min-width: 250px;
                        @include flex(space-between);
                        margin-left: 30px;
                        padding: 15px 20px;


                        @include desktop {
                            @media (max-width: 1400px) {
                               height: auto;
                               font-size: 14px !important;
                               padding: 10px 15px;
                               min-width: 215px;
                            }
                        }

                        @include tablet {
                            margin-left: 0;
                            margin-top: 20px;
                        }

                        .icon-row {
                            margin-right: 15px;
                            height: 33px;

                           img {
                            max-width: 40px;
                            height: 33px;
                           }

                           @include desktop {
                                @media (max-width: 1400px) {
                                    margin-right: 0;
                                    height: 30px;
                                    img {
                                        max-width: 30px;
                                       height: 30px;
                                        
                                    }
                                }
                            }
                        }

                        .text-row {
                            font-family: "Roboto", sans-serif;
                            font-weight: 500;
                            letter-spacing: 0.4px;
                            color: #000;
                        }

                        &:hover {
                            background-color: black;
                          

                            .text-row {
                                color: #fff
                            }
                        }
                    }
                }
            }
        }

        .right-col {
            @include flex(flex-end);
            align-items: center;

            @media (max-width: 600px) {
                display: none;
            }

            .offer-items {
                list-style: none;

                position: relative;
                right: -40px;

                @include tablet {
                    right: -20px;
                }

                li {
                    position: relative;
                    text-align: right;
                    padding-right: 37px;
                    color: #fff;
                    font-family: "Roboto", sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 70px;
                    max-width: 138px;


                    span {
                        display: block;
                        font-weight: 500;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        
                        width: 10px;
                        height: 10px;

                        background-color: #fff;
                        right: 0;
                        top: 40%;
                    }


                    @include desktop {
                        @media (max-width: 1400px) {
                            font-size: 10px;
                            max-width: 110px;
                            margin-bottom: 55px;
                            padding-right: 25px;

                            &::after {
                                width: 7px;
                                height: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}