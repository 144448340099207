@keyframes widthUpAnimation {
    0% {
      left: 2000px;
    }
    
    100% {
        left: -1000px
    }
}

.about-section {
    position: relative;
    min-height: 500px;
    overflow: hidden;

   

    .heading {
        padding-top: 27px;
        h2 {
            max-width: 1180px;
            letter-spacing: 0.14px;
            margin-top: 0;
            @include fontsize(35px,26px,22px);
            color: #fff;
            text-transform: uppercase;
        }

        p {
            position: relative;
            @include fontsize(16px,16px,16px);
            color: rgba($color: #fff, $alpha: .25);
            text-transform: uppercase;
            width: fit-content;

            @include phone {
                max-width: 400px;
            }

            span {
                position: relative;
                z-index: 10;
            }

            &::before {
                position: absolute;
                content: "";

                height: 100%;
                width: 108%;

                background-color: #191B1D;

                z-index: 8;

                left: -4%;

                @include phone {
                    width: 100%;
                }
            }

            &::after {
                position: absolute;
                content: "";

                height: 1px;
                width: 9999px;

                background-color: #fff;
                opacity: .15;

                left: -1000px;
                top: 50%;

                z-index: 6;
                

                animation: widthUpAnimation 3s ease-in ;
            }
        }
    }

    .interactive-image {
        position: relative;

        width: 100%;
        margin-top: 27px;
        border-radius: 35px;
        overflow: hidden;

        @include tablet {
            border-radius: 25px;
        }

        @include phone {
            border-radius: 15px;
        }

        .image-block {
            width: 100%;

            img {
                width: 100%;

                

                @include phone {
                    min-height: 450px;
                    object-fit: cover;
                    object-position: -170px top;
                }

            }
        }

        .interactive-elements {
            position: absolute;
            width: 100%;
            height: 100%;

            left: 0;
            top: 0;

            .tool {
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    width: 25px;
                    height: 25px;
                    background-color: #FAF6ED;
                    font-size: 26px;
                    border-radius: 100px;
                    color: #5A280D;

                    &:hover {
                        background-color: #5A280D;
                        color: #fff;
                    }
                }

                @include phone {

                    &.tool-1 {
                        left: 170px !important;
                    }
    
                    &.tool-2 {
                        left: 170px !important;
                    }
    
                    &.tool-3 {
                        left: 50px !important;
                    }
                }


            }

            .react-tooltip {
                max-width: 335px;
                background: #FAF6ED !important;
                z-index: 500;
                border-radius: 15px;
                overflow: hidden;

                h4 {
                    @include fontsize(14px,13px,12px);
                    font-weight: 500 !important;
                    color: #000;
                    margin: 0;
                    margin-bottom: 4px;
                }

                p {
                    font-family: "Roboto", sans-serif;
                    font-size: 11px;
                    font-weight: 400;
                    color: #000;

                    opacity: 0.49;
                    margin: 0;
                }


               
            }
        }
    }



}