

.our-works-section {

    position: relative;
    overflow: hidden;
    height: 790px;

    @include tablet {
        height: 1130px;
    }

    &::before {
        content: "alyans";
        position: absolute;

        @include fontsize(310px,250px,170px);
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        color: #202223;

        left: -50px;
        top: 350px;
    }

    .row-container {
        padding-top: 145px;
        @include flex(space-between);

        @include tablet {
            width: 100%;
        }
    }

    .left-col {

        width: calc(100% - 670px);

        margin-top: -78px;
        position: relative;
        left: -40px;

        @include tablet {
            width: 100%;
            left: 0;
        }

        .heading {
            @include tablet {
                width: 90%;
                margin: 0 auto;
            }

            svg {
                width: 100%;
            }
        }
    }

    .right-col {
        width: 758px;
        position: absolute;
        right: -109px;

        @include tablet {
            position: relative;
            width: 100%;
            margin-top: 60px;
            right: 0;
        }

        .buttons-wrap {
            position: absolute;
            width: fit-content;
            float: right;
            top: -90px;
            right: 109px;

            @include tablet {
                right: 6%;
            }

            button {

                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                width: 50px;
                height: 50px;
                border-radius: 50px;
                border: none;
                box-shadow: none;
                margin-left: 15px;
                cursor: pointer;
                transition: all .35s ease-in-out;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .video-slider {

            
            .slick-arrow {
                display: none !important;
            }

            .slick-list {
                padding-left: 0; /* Убираем левый отступ для слайдов */
                margin-left: 0;
            }
            .slick-slide {
                float: right;

                @include tablet {
                    float: left;
                }

                .mockup-container {
                    position: relative;
                    width: 294px; /* Ширина по размеру мокапа */
                    height: 593px;

                    @include tablet {
                        width: 250px;
                        height: 500px;
                    }

                    @include phone {
                        width: 295px;
                        height: 595px;

                       @media (max-width: 410px) {
                        width: 265px;
                        height: 535px;
                       }
                     
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        pointer-events: none;

                        &.mockup-image {
                            z-index: 3;
                        }

                        &.featured-image {
                            z-index: 1;

                            border-radius: 60px;
                            width: 99%;
                            left: 1px;
                        }
                    }

                    iframe {
                        width: 97%;
                        height: 98%;
                        position: absolute;
                        top: 0px;
                        left: 1px;
                        z-index: 0;
                        border-radius: 50px;
                        object-fit: cover;
                        z-index: 2;
                    }

                    .play-button {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 2;
                        font-size: 24px;
                        background: none;
                        color: white;
                        border: none;
                        border-radius: 50%;
                        padding: 10px;
                        cursor: pointer;
                      }
                }
            }
        }
    }
}