.header {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: fit-content;

    z-index: 999;
    
    .row-container {
        @include flex(space-between);
        align-items: center;
        padding-top: 20px;
    }

    .header-element {
        &.head-logo {
            transition-timing-function: ease-out;

            img {
                max-width: 171px;

                @include desktop {
                    @media (max-width: 1400px) {
                        max-width: 120px;
                    }
                }
            }
        }

        &.head-about {
            transition-timing-function: ease-out;

            @include phone {
                display: none;
            }

            p {
                color: rgba($color: #ffffff, $alpha: 0.7);
                max-width: 188px;
                font-family: "Roboto", sans-serif;
                @include fontsize(14px,13px,12px);
                font-weight: 300;

                @include desktop {
                    @media (max-width: 1400px) {
                        max-width: 150px;
                        font-size: 11px !important;
                    }
                }

                span {
                    color: #fff;
                    font-weight: 400;
                }
            }
        }

        &.head-designer-button {
            transition-timing-function: ease-out;

            @include tablet {
                display: none;
            }

            a {
                background-color: #000;
                color: #FFC9B3;
                @include fontsize(13px,13px,13px);
                text-transform: uppercase;
                text-decoration: none;

                padding: 15px 30px;
                border-radius: 60px;
                transition: all .2s ease-in-out;

                @include desktop {
                    @media (max-width: 1400px) {
                        font-size: 11px !important;
                        padding: 12px 20px;
                    }
                }

                &:hover {
                    background-color: #fff;
                    color: #843802;
                }

            }
        }

        &.head-social-networks {
            transition-timing-function: ease-out;

            @include phone {
                display: none !important
            }

            @include flex(space-between);
            align-items: center;

            .col-left {
               
                max-width: 85px;
                margin-right: 10px;

                p {
                    color: #fff;
                    font-family: "Roboto", sans-serif;
                    @include fontsize(14px,14px,13px);
                    font-weight: 300;

                    span {
                        color: #87FB48;
                    }

                    
                }

                @include desktop {
                    @media (max-width: 1400px) {
                        max-width: 80px;
                        margin-right: 0;

                        p {
                            font-size: 11px !important;
                        }
                    }
                }
            }

            .col-right {

                img {
                    max-width: 34px;

                    &.whatsapp {
                        margin-right: 5px;
                    }
                    @include desktop {
                        @media (max-width: 1400px) {
                            max-width: 25px;
                        }
                    }
                    
                }
            }
        }

        &.head-phone-number {
            transition-timing-function: ease-out;
            
            p {
                font-family: "Roboto", sans-serif;
                color: #fff;
                margin: 0;
                line-height: 1;

                a {
                    font-family: "Roboto", sans-serif;
                    text-decoration: none;
                    color: #fff;
                }
            }

            .schedule {
                font-size: 10px;
                margin-bottom: 2px;

                @include desktop {
                    @media (max-width: 1400px) {
                        font-size: 8px;
                    }
                }
            }

            .phone-number {
                a {
                    font-size: 14px;
                    font-weight: 500;

                    @include desktop {
                        @media (max-width: 1400px) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .schedule-call {
                line-height: 0.8;
                a {
                    font-size: 9.5px;
                    color: #82E134;
                    border-bottom: 1px dashed #82E134;

                    @include desktop {
                        @media (max-width: 1400px) {
                            font-size: 8.5px;
                        }
                    }
                }
            }
        }
    }

    
}