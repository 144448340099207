@keyframes footerPhoneAnimation {
    0% {
      transform: translateY(0px);
    }
    
    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.footer-section {
    position: relative;

    .row-container {
        max-width: 1385px;
        padding-top: 35px;
        padding-bottom: 40px;
    }

    .footer-top {
        @include flex(space-between);
        
        .col {
            &.left {
                width: calc(100% - 677px - 30px);
                @include flex(space-between);
                align-items: flex-start;

                @include desktop {
                    @media (max-width: 1345px) {
                        width: 220px;
                    }
                }

                @include tablet {
                    width: 100%;
                }
                

                .info {
                    padding-top: 45px;

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            @include flex(flex-start);
                            margin-bottom: 57px;

                            .icon {
                                padding-top: 5px;
                            }

                            .text-wrap {
                                padding-left: 10px;
                                max-width: 190px;

                                strong {
                                    color: #fff;
                                    font-size: 14px;
                                }

                                p {
                                    color: rgba($color: #fff, $alpha: .6);
                                    font-size: 12px;
                                    letter-spacing: 0.8px;
                                    margin: 0;
                                    margin-top: 3px;

                                }

                                a.gis {
                                    font-size: 10px;
                                    color: #58FF71;
                                    letter-spacing: 0.7px;
                                    text-decoration: none;
                                    border-bottom: 1px dashed #58FF71;

                                }

                                a.tel {
                                    display: block;
                                    color: rgba($color: #fff, $alpha: .6);
                                    font-size: 12px;
                                    text-decoration: none;
                                    letter-spacing: 0.8px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .see-you-soon {



                    @include desktop {
                        @media (max-width: 1345px) {
                            display: none;
                        }
                    }

                    @include tablet {
                        margin-right: 10%;

                        width: 100%;
                        margin-right: 0;

                        

                        @media (min-width: 768px) {
                          

                            .additional-services {
                                width: 100%;
                                max-width: 100% !important;

                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                            }
                        }
                    }

                    @include phone {
                        margin-right: 0;
                        width: 100%
                    }

                    h3 {
                        @include fontsize(18px,17px,16px);
                        font-weight: 600;
                        color: #fff;
                        margin-top: 0;
                        margin-bottom: 25px;
                        letter-spacing: 0.9px;
                    }

                    a {
                        display: block;
                        margin-bottom: 10px;

                        @include tablet {
                            @media (min-width: 768px) {
                               width: 48%;
                               max-width: 100%;
                            }
                        }

                        @include phone {
                            width: 100%;
                        }
                    }

                    img {
                        border-radius: 20px;
                        max-width: 280px;

                        @include tablet {
                            @media (min-width: 768px) {
                                width: 100%;
                                max-width: 100%;
                            }
                        }

                        @include phone {
                            width: 100%;
                            object-fit: cover;
                            object-position: top center;
                            max-width: 100%;
                        }
                    }


                    .additional-services {
                        max-width: 280px;

                        @include phone {
                            max-width: 100%;
                        }

                        a {
                            color: #ffb93e;
                            text-decoration: none
                        }
                    }
                }
            }

            &.right {
                @include flex(space-between);
                width: 677px;
                background-color: #212325;
                border-radius: 20px;
                max-height: 377px;
                overflow: hidden;

                @include desktop {
                    @media (max-width: 1345px) {
                        width: 73%;
                    }
                }

                @include tablet {
                    width: 100%;
                    margin-top: 30px;
                }

                @include phone {
                    max-height: 685px;
                }

                .text-wrap {
                    max-width: 255px;
                    padding-top: 30px;
                    padding-left: 48px;

                    @include phone {
                        @include flex(center);
                        max-width: 100%;
                        text-align: center;
                        padding: 20px;
                    }
                }

                .image {
                    // max-width: ;
                    max-width: 312px;
                    margin-right: 12px;
                    margin-top: 44px;
                    margin-left: 10px;

                    animation: footerPhoneAnimation 6s ease-in-out infinite ;

                    @include desktop {
                        @media (max-width: 1345px) {
                           margin-right: 10%;
                        }

                        @media (max-width: 1080px) {
                            margin-right: 15px;
                         }
                    }

                    @include tablet {
                        margin-right: 10%;
                    }

                    @include phone {
                        margin: 0 auto;
                        margin-top: 30px;
                    }

                    img {
                        width: 100%;
                    }
                }
               
                h3 {
                    max-width: 255px;
                    @include fontsize(24px,20px,18px);
                    font-weight: 600;
                    color: #fff;
                    text-transform: uppercase;
                    letter-spacing: 1.6px;

                    @include phone {
                        max-width: 340px;
                    }
                }

                p {
                    max-width: 220px;
                    @include fontsize(24px,20px,18px);
                    font-weight: 300;
                    color: #fff;
                    text-transform: uppercase;
                    margin: 28px 0 36px;

                    letter-spacing: 1.7px;

                    @include phone {
                        max-width: 100%;
                    }
                }
                a {
                    display: block;
                    width: 100%;
                    background-color: #fff;
                    color: #000;
                    font-weight: 600;
                    text-align: center;
                    @include fontsize(20px,19px,18px);
                    text-decoration: none;
                    border-radius: 100px;
                    padding: 30px 0;
                    transition: all .35s ease-in-out;

                    &:hover {
                        background-color: #D18945;
                        color: #fff;
                    }
                }

            }
        }
    }

    .footer-bottom {
        @include flex(space-between);
        align-items: center;
        color: #fff;
        margin-top: 83px;

        @include phone {
            justify-content: center;
        }

        .logo {
            
            img {
                max-width: 185px;
                width: 100%;
            }
        }
        
        .desc {
            max-width: 190px;

            p {
                font-size: 13px;
                font-weight: 300;

                strong {
                    font-weight: 500;
                }
            }
        }

        .copyright {

            text-align: center;

            strong {
                font-weight: 500;
            }

            p {
                font-size: 13px;
                font-weight: 300;
                margin-top: 5px;
                margin-bottom: 0;
                
            }
        }

        .developper {
            a {
                @include flex(space-between);
                align-items: center;
                text-decoration: none;
                color: #989898;
                font-size: 16px;

                span {
                    margin-right: 20px;
                }
            }
            
            img {
                max-width: 75px;
            }
        }

        .col {
            @include tablet {
                width: 28%;

                &.developper {
                    width: 250px;
                    margin: 0 auto;
                    margin-top: 30px;
                }
               
            }

            @include phone {
                text-align: center;
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }
}